import { fromJS } from "immutable";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  UPDATE_FIELD_VALUE,
  UPDATE_SHOW_PASSWORD,
  UPDATE_LANGUAGE_CHANGE,
  UPDATE_SHOW_FORGOT_PASSWORD,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  UPDATE_SHOW_RESET_PASSWORD,
  GET_SECURITY_QUESTION_SUCCESS,
  GET_SECURITY_QUESTION_FAILURE,
  UPDATE_SHOW_SECURITY_QUES_SCREEN,
  UPDATING_PASSWORD_SEC_ANS,
  UPDATE_PASSWD_SEC_ANS_SUCCESS,
  UPDATE_PASSWD_SEC_ANS_FAILURE,
  FETCHING_GET_CLIENT_DETAILS,
  FETCH_GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_FETCH_COMPLETED,
  BACK_TO_LOGIN,
  FETCHING_CHECK_USER_EXIST,
  FETCHING_CHECK_USER_EXIST_COMPLETED,
  FETCHING_LIST_SECURITY_QUESTION,
  FETCHED_LIST_SECURITY_QUESTION,
  FETCH_LIST_SECURITY_QUESTION_COMPLETED,
  VERIFYING_SECURITY_QUESTION_ANSWER,
  VERIFYING_SECURITY_QUESTION_ANSWER_SUCCESS,
  VERIFYING_SECURITY_QUESTION_ANSWER_FAILED,
  ABOUT_TO_LOGOUT,
  LOGOUT,
  TIMER_RESET_SUCCESS,
  SET_ACCENTURE_SSO_LOGIN_FLAG,
  SET_CLIENT_SSO_LOGIN_FLAG,
  FETCHING_REFRESH_TOKEN,
  FETCH_REFRESH_TOKEN,
  REFRESH_TOKEN_FETCH_FAILED,
  CONFIRMING_GDPR_MESSAGE,
  GDPR_MESSAGE_CONFIRMED,
  GDPR_MESSAGE_CONFIRM_FAILED,
  INIT_IDLE_TIMER,
  INIT_IDLE_TIMER_COUNTDOWN,
  SSO_LOGIN_FAILED,
  INIT_MFA_OTP,
  MFA_OTP_SENT,
  MFA_OTP_FAILED,
  NOT_YET_CONFIGURED,
  FORGOT_PASS_MFA_OTP_SENT,
  SUBMITTING_QNA_SUCCESS,
  SUBMITTING_QNA,
  SUBMITTING_QNA_FAILED,
} from "./actionTypes";

export const initialState = fromJS({
  isFetching: false,
  isLoading: false,
  isAuthenticated: false,
  errorMessage: "",
  numberOfWrongAttempts: 0,
  sessionDetails: {
    userName: "",
    roleId: "",
    baseRoleId: "",
    userId: "",
    loginId: "",
    menus: [],
    token: "",
    primaryEmail: "",
    lastPwdChangedOn: "",
    passwordExpiryDuration: 0,
    suppliers: [],
    defaultMinDate: "",
  },
  userName: "",
  password: "",
  showPassword: false,
  selectedLanguage: "enGB",
  email: "",
  isSendingForgotPassword: false,
  showResetPassword: false,
  userNameToResetPwd: "",
  getSecurityQuesStatus: false,
  showSecurityQuesScreen: false,
  captchaCharacters: "",
  newPassword: "",
  confirmPassword: "",
  securityAns: "",
  updatePasswdBySecAnsStatus: false,
  emailSent: false,
  isCheckUserExist: false,
  isSecurityQuesList: false,
  securityQuestionList: [],
  passwordChangedBySecQues: false,
  question: "",
  verifySecurityQuestionAnswerStatus: false,
  aboutToLogout: false,
  setAccentureSSOLoginFlag: true,
  setClientSSOLoginFlag: true,
  SsoType: "",
  fetchRefreshToken: false,
  fetchRefreshTokenFail: false,
  confirmingGDPRMsg: false,
  gdprConfirmed: false,
  maxIdleTimeAllowed: -1,
  isSsoLoginFailed: false,
  MFAOTPSent: false,
  authenticating: false,
  notYetConfigured: false,
  securityOTP: false,
  isSubmittedQnA: false,
});

function loginPageReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return state
        .set("isFetching", true)
        .set("authenticating", true)
        .set("numberOfWrongAttempts", state.numberOfWrongAttempts + 1);
    case LOGIN_SUCCESS:
      return state
        .set("isFetching", false)
        .set("isAuthenticated", true)
        .set("errorMessage", "")
        .set("numberOfWrongAttempts", 0)
        .set("sessionDetails", action.data)
        .set("authenticating", false);

    case LOGIN_FAILURE:
      return state
        .set("isFetching", false)
        .set("isAuthenticated", false)
        .set("errorMessage", action.message)
        .set("authenticating", false);
    case SSO_LOGIN_FAILED:
      return state.set("isSsoLoginFailed", action.data);
    case UPDATE_FIELD_VALUE:
      return state.set(action.field.name, action.field.value);
    case UPDATE_SHOW_PASSWORD:
      return state.set("showPassword", action.showPassword);
    case UPDATE_LANGUAGE_CHANGE:
      return state.set("selectedLanguage", action.language);
    case UPDATE_SHOW_FORGOT_PASSWORD:
      return state.set("showForgotPassword", action.showForgotPassword);
    case FORGOT_PASSWORD_REQUEST:
      return state.set("isSendingForgotPassword", true);
    case FORGOT_PASSWORD_SUCCESS:
      return state
        .set("isSendingForgotPassword", false)
        .set("emailSent", true)
        .set("showForgotPassword", false)
        .set("captchaCharacters", "");

    case FORGOT_PASSWORD_FAILURE:
      return state
        .set("isSendingForgotPassword", false)
        .set("captchaCharacters", "");

    case UPDATE_SHOW_RESET_PASSWORD: {
      return state
        .set("showResetPassword", action.showResetPassword)
        .set("showSecurityQuesScreen", false)
        .set("captchaCharacters", "")
        .set("securityAns", "")
        .set("question", "");
    }

    case GET_SECURITY_QUESTION_SUCCESS: {
      return state.set("getSecurityQuesStatus", false);
    }

    case GET_SECURITY_QUESTION_FAILURE: {
      return state.set("getSecurityQuesStatus", false);
    }

    case UPDATE_SHOW_SECURITY_QUES_SCREEN:
      return state
        .set("showSecurityQuesScreen", action.showSecurityQuesScreen)
        .set("showResetPassword", false);

    case UPDATING_PASSWORD_SEC_ANS:
      return state.set("updatePasswdBySecAnsStatus", true);

    case UPDATE_PASSWD_SEC_ANS_SUCCESS:
      return state
        .set("updatePasswdBySecAnsStatus", false)
        .set("userNameToResetPwd", "")
        .set("newPassword", "")
        .set("confirmPassword", "")
        .set("securityAns", "")
        .set("showSecurityQuesScreen", false)
        .set("question", "")
        .set("passwordChangedBySecQues", true)
        .set("captchaCharacters", "");

    case UPDATE_PASSWD_SEC_ANS_FAILURE:
      return state
        .set("updatePasswdBySecAnsStatus", false)
        .set("newPassword", "")
        .set("confirmPassword", "");

    case FETCHING_GET_CLIENT_DETAILS:
      return state.set("isClientDetailsFetching", true);
    case FETCH_GET_CLIENT_DETAILS:
      return state.set("clientDetails", action.data);
    case GET_CLIENT_DETAILS_FETCH_COMPLETED:
      return state.set("isClientDetailsFetching", false);
    case BACK_TO_LOGIN:
      return state
        .set("showResetPassword", false)
        .set("showSecurityQuesScreen", false)
        .set("emailSent", false)
        .set("passwordChangedBySecQues", false)
        .set("securityQuestionList", [])
        .set("question", "")
        .set("newPassword", "")
        .set("confirmPassword", "");
    case FETCHING_CHECK_USER_EXIST:
      return state.set("isCheckUserExist", true);
    case FETCHING_CHECK_USER_EXIST_COMPLETED:
      return state.set("isCheckUserExist", false).set("captchaCharacters", "");

    case FETCHING_LIST_SECURITY_QUESTION:
      return state.set("isSecurityQuesList", true);
    case FETCHED_LIST_SECURITY_QUESTION:
      return state.set("securityQuestionList", action.data);
    case FETCH_LIST_SECURITY_QUESTION_COMPLETED:
      return state.set("isSecurityQuesList", false);
    case VERIFYING_SECURITY_QUESTION_ANSWER:
      return state.set("verifySecurityQuestionAnswerStatus", true);
    case VERIFYING_SECURITY_QUESTION_ANSWER_SUCCESS:
      return state
        .set("verifySecurityQuestionAnswerStatus", false)
        .set("question", "")
        .set("securityAns", "");
    case VERIFYING_SECURITY_QUESTION_ANSWER_FAILED:
      return state
        .set("verifySecurityQuestionAnswerStatus", false)
        .set("question", "")
        .set("securityAns", "");

    // Accenture SSO login
    case SET_ACCENTURE_SSO_LOGIN_FLAG:
      return state
        .set("setAccentureSSOLoginFlag", false)
        .set("SsoType", action.SsoType);

    // Client SSO login
    case SET_CLIENT_SSO_LOGIN_FLAG:
      return state
        .set("setClientSSOLoginFlag", false)
        .set("SsoType", action.SsoType);

    case INIT_IDLE_TIMER:
      return state.set("maxIdleTimeAllowed", -1);
    case INIT_IDLE_TIMER_COUNTDOWN:
      return state.set("maxIdleTimeAllowed", action.timeInSecs);
    case ABOUT_TO_LOGOUT:
      return state.set("aboutToLogout", true);
    case LOGOUT:
      return state
        .set("sessionDetails", {})
        .set("isAuthenticated", false)
        .set("aboutToLogout", false);
    case TIMER_RESET_SUCCESS:
      return state.set("aboutToLogout", false);

    case FETCHING_REFRESH_TOKEN:
      return state
        .set("fetchRefreshToken", true)
        .set("fetchRefreshTokenFail", false);
    case FETCH_REFRESH_TOKEN:
      return state
        .set("sessionDetails", action.data)
        .set("fetchRefreshToken", false)
        .set("fetchRefreshTokenFail", false);
    case REFRESH_TOKEN_FETCH_FAILED:
      return state
        .set("fetchRefreshToken", false)
        .set("fetchRefreshTokenFail", true);

    case CONFIRMING_GDPR_MESSAGE:
      return state.set("confirmingGDPRMsg", true);
    case GDPR_MESSAGE_CONFIRMED:
      return state.set("confirmingGDPRMsg", false).set("gdprConfirmed", true);
    case GDPR_MESSAGE_CONFIRM_FAILED:
      return state
        .set("confirmingGDPRMsg", false)
        .set("gdprConfirmed", false)
        .set("errorMessage", action.message);
    case INIT_MFA_OTP:
      return state.set("authenticating", true);
    case MFA_OTP_SENT:
      return state.set("authenticating", false).set("MFAOTPSent", true);
    case MFA_OTP_FAILED:
      return state.set("authenticating", false);
    // case INIT_AUTHENTICATE: {
    //   return state
    //     .set("authenticating", true)
    //     .set("numberOfWrongAttempts", state.numberOfWrongAttempts + 1);
    // }
    case NOT_YET_CONFIGURED: {
      return state.set("notYetConfigured", true);
    }
    case FORGOT_PASS_MFA_OTP_SENT:
      return state.set("securityOTP", true);
    case SUBMITTING_QNA_SUCCESS:
      return state.set("isSubmittedQnA", false).set("securityOTP", false);
    case SUBMITTING_QNA:
      return state.set("isSubmittedQnA", true);
    case SUBMITTING_QNA_FAILED:
      return state.set("isSubmittedQnA", false); //.set("securityOTP", false);

    default:
      return state;
  }
}

export default loginPageReducer;
