import {
  call,
  put,
  all,
  takeEvery,
  takeLatest,
  delay,
} from "redux-saga/effects";
import {
  loginSuccess,
  loginFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  updatePasswordSecAnsSuccess,
  updatePasswordSecAnsFailed,
  checkAuthTimeout,
  fetchingGetClientDetails,
  fetchGetClientDetails,
  fetchGetClientDetailsCompleted,
  fetchingCheckUserExist,
  fetchCheckUserExistCompleted,
  fetchingListSecurityQuestions,
  fetchedListSecurityQuestions,
  fetchListSecurityQuestionsCompleted,
  submitForgotPasswordAction,
  updatingPasswordSecAns,
  verifyingSecurityQuestionAnswer,
  verifySecurityQuestionAnswerSuccess,
  verifySecurityQuestionAnswerFailed,
  loggedout,
  initIdleTimerCountdown,
  confirmingGDPRMsg,
  gdprMsgConfirmed,
  gdprMsgConfirmFailed,
  fetchingRefreshToken,
  refreshTokenFetchFailed,
  logout,
  initRefreshToken,
  refreshToken,
  ssoLoginFailed,
  MFASuccess,
  MFAFailed,
  notYetConfigured,
  securityOTPSuccess,
  securityOTPFailed,
  submittingQnASuccess,
  submittingQnAFailed,
} from "./actions";
import {
  LOGIN_REQUEST,
  LOGIN_URL,
  INIT_UPDATE_PASSWORD_BY_SECURITY_ANS,
  INIT_FORGOT_PASSWORD,
  INIT_LOGOUT,
  INIT_GET_CLIENT_DETAILS,
  INIT_CHECK_USER_EXIST,
  INIT_LIST_SECURITY_QUESTION,
  INIT_VERIFY_SECURITY_QUESTION_ANSWER,
  INIT_REFRESH_TOKEN,
  REFRESH_TOKEN,
  INIT_CONFIRM_GDPR_MESSAGE,
  INIT_IDLE_TIMER,
  CHECK_AUTH_STATUS,
  CHECK_AUTH_TIMEOUT,
  INIT_MFA_OTP,
  INIT_FORGOT_PASS_MFA_OTP,
} from "./actionTypes";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import axios from "../../../config/axios";
import {
  getRequestDetails,
  postRequestDetails,
  putRequestDetails,
} from "../../../config/requestHeaders";
import {
  userLogoutTimeInSecs,
  tokenExpirationTimeInSecs,
} from "../../../config";

export default function* rootLoginSaga() {
  yield all([
    takeLatest(LOGIN_REQUEST, loginUser),
    takeEvery(CHECK_AUTH_STATUS, checkAuthStatusSaga),
    takeLatest(CHECK_AUTH_TIMEOUT, checkAuthTimeoutSaga),
    takeEvery(
      INIT_UPDATE_PASSWORD_BY_SECURITY_ANS,
      updatePasswordSecurityAnswerSaga
    ),
    takeEvery(INIT_FORGOT_PASSWORD, initForgotPasswordSaga),
    takeLatest(INIT_LOGOUT, logoutSaga),
    takeEvery(INIT_GET_CLIENT_DETAILS, getClientDetailsSaga),
    takeEvery(INIT_CHECK_USER_EXIST, initCheckUserExistSaga),
    takeEvery(INIT_LIST_SECURITY_QUESTION, initListSecurityQuestionsSaga),
    takeEvery(
      INIT_VERIFY_SECURITY_QUESTION_ANSWER,
      initVerifySecurityQuestionAnswerSaga
    ),
    takeLatest(INIT_IDLE_TIMER, initIdleTimerSaga),
    takeLatest(INIT_CONFIRM_GDPR_MESSAGE, initConfirmGDPRMsgSaga),
    takeLatest(INIT_REFRESH_TOKEN, initRefreshTokenSaga),
    takeLatest(REFRESH_TOKEN, getRefreshTokenSaga),
    takeLatest(INIT_MFA_OTP, initMFAOTPSaga),
    takeLatest(INIT_FORGOT_PASS_MFA_OTP, initQnAOTPSaga),
  ]);
}

function* loginUser(action) {
  let sessionDetails = {};
  try {
    const url = action.loginType === "ssoLogin" ? "/Login/ssologin" : LOGIN_URL;
    if (action.loginType === "ssoLogin") {
      const clientDetails =
        JSON.parse(sessionStorage.getItem("clientDetails")) || {};
      const clientConnString = clientDetails.token;
      const clientSsoDetails = sessionStorage.getItem("isClientSSO");
      console.log(clientSsoDetails);
      postRequestDetails.headers = {
        "Content-Type": "application/json",
        "X-App-Info": clientConnString,
        clientName: btoa(clientDetails.name),
        isClientSSO: clientSsoDetails ? true : false,
      };
      postRequestDetails.data = {
        token: action.token,
      };
      // console.log(postRequestDetails);
    } else {
      yield (postRequestDetails.data = {
        userName: btoa(action.credential.username),
        password: btoa(action.credential.password),
        ClientName: btoa(action.credential.ClientName),
        ClientID: action.credential.ClientID,
        otp: action.credential.otp,
      });
    }
    const response = yield call(axios, url, postRequestDetails);
    const user = response.data;
    let expirationDate = new Date(
      new Date().getTime() + tokenExpirationTimeInSecs * 1000
    );
    const sessionStartsAtInSec = userLogoutTimeInSecs * 60;
    let sessionStartsAt = new Date(
      new Date().getTime() + sessionStartsAtInSec * 1000
    );

    // convert minute to millisec to new Date() obj
    if (user) {
      const lang = user.languageID && user.languageID.trim();
      sessionDetails = {
        isAuthenticated: true,
        userName: user.userName,
        roleId: user.roleID,
        baseRoleId: user.baseRoleID,
        userId: user.userID,
        loginId: user.loginId,
        menus: user.menus,
        buttons: user.buttons,
        token: user.authenticationToken,
        expirationDate,
        primaryEmail: user.primaryEmail,
        languageId: lang || "enGB",
        lastPwdChangedOn: user.lastPwdChangedOn,
        passwordExpiryDuration: user.passwordExpiryDuration,
        suppliers: user.suppliers,
        encryptedUserName: user.encryptedUserName,
        displayGDPR: user.displayGDPR,
        gdprMessage: user.gdprMessage,
        maxReport: user.maxReport,
        sessionStartsAt,
        applicationGDPRMessage: user.applicationGDPRMessage,
      };
      sessionStorage.setItem("sessionDetails", JSON.stringify(sessionDetails));
      yield put(checkAuthTimeout(sessionStartsAtInSec));
      yield put(loginSuccess(sessionDetails));
      yield put(initRefreshToken(tokenExpirationTimeInSecs - 60));
      if (action.callback) {
        action.callback(response.data);
      }
    } else {
      yield put(
        loginFailure(
          action.loginType === "ssoLogin"
            ? "ssoLoginFailed"
            : "Username or Password is wrong"
        )
      );
      if (action.callback) {
        action.callback(response.data);
      }
      if (action.loginType === "ssoLogin") {
        yield put(ssoLoginFailed(true));
      }
    }
  } catch (err) {
    sessionStorage.setItem("sessionDetails", JSON.stringify(sessionDetails));
    yield put(loginFailure(""));
  }
}
function* initMFAOTPSaga(action) {
  const url = "/Login/sendLoginOTP";
  try {
    const requestDetails = { ...postRequestDetails };
    yield (requestDetails.data = {
      userName: btoa(action.payload.username),
      password: btoa(action.payload.password),
      ClientName: btoa(action.payload.ClientName),
      ClientID: action.payload.ClientID,
    });
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200 || response.status === 202) {
      yield put(MFASuccess());
    }
  } catch (e) {
    const { data } = e || {};
    if (data && data.messageCode === "MSP-LC-016") {
      yield put(notYetConfigured());
    }
    yield put(MFAFailed());
  }
}
function* initForgotPasswordSaga(action) {
  yield put(submitForgotPasswordAction());
  var url = "/Login/forgotPassword";
  try {
    postRequestDetails.data = JSON.stringify(action.data);
    const response = yield call(axios, url, postRequestDetails);
    if (response.status === 202) {
      yield put(forgotPasswordSuccess());
    } else {
      yield put(forgotPasswordFailure());
    }
  } catch (err) {
    yield put(forgotPasswordFailure());
  }
}

function* updatePasswordSecurityAnswerSaga(action) {
  yield put(updatingPasswordSecAns());
  const url = "/Login/UpdatePasswordSecurityAnswer";
  try {
    postRequestDetails.data = {
      password: btoa(action.data.password),
      confirmPassword: btoa(action.data.confirmPassword),
      userName: action.data.userName,
      clientName: action.data.clientName,
    };
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 202) {
      yield put(updatePasswordSecAnsSuccess());
      const notification = buildNotification({
        message: "notification.forgotPassword.changeSuccess",
        type: "success",
      });
      store.addNotification({
        ...notification,
        dismiss: {
          duration: 2000,
        },
      });
    } else {
      yield put(updatePasswordSecAnsFailed());
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
    yield put(updatePasswordSecAnsFailed());
  }
}
// get client details
function* getClientDetailsSaga(action) {
  const sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  const languageID = sessionDetails.languageId;
  yield put(fetchingGetClientDetails());
  const url = `/ClientManagement/ClientById?clientId=1&languageID=${languageID}`;
  try {
    const response = yield call(axios, url, getRequestDetails);

    if (response && response.status === 200) {
      const { client } = yield response.data || {};
      const localStorageItem = yield sessionStorage.getItem("sessionDetails");
      const minDate = new Date();
      const noOfMonths = client && client.noOfYears * 12;
      client && minDate.setMonth(minDate.getMonth() - noOfMonths);
      const updatedLocalStorageItem = yield {
        ...JSON.parse(localStorageItem),
        isExtHelpDeskLinkUsed: client && client.isExtHelpDeskLinkUsed,
        contactSupportEmailAddress: client && client.emailAddress,
        contactSupportPhoneNumber: client && client.phoneNumber,
        defaultMinDate: Date.parse(minDate.toString()),
      };
      yield sessionStorage.setItem(
        "sessionDetails",
        JSON.stringify(updatedLocalStorageItem)
      );
      yield put(fetchGetClientDetails(response.data));
    }
    yield put(fetchGetClientDetailsCompleted());
  } catch (error) {
    yield put(fetchGetClientDetailsCompleted("Failed to Fetch Data"));
  }
}

function* checkAuthStatusSaga(action) {
  const sessionDetails =
    JSON.parse(sessionStorage.getItem("sessionDetails")) || {};
  const { token, expirationDate } = sessionDetails;

  if (!token) {
    yield put(logout());
  } else {
    const expirationTime = new Date(expirationDate);

    if (expirationTime < new Date()) {
      yield put(logout());
    } else {
      yield put(loginSuccess(sessionDetails));
      const timeInSecond =
        (expirationTime.getTime() - new Date().getTime()) / 1000;
      yield delay(60 * 1000);
      yield put(checkAuthTimeout(timeInSecond));
    }
  }
}

function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime * 1000); // converts to millisec
  yield put(logout());
}

function* initCheckUserExistSaga(action) {
  yield put(fetchingCheckUserExist());
  const url = `/Users/UserAlreadyExists?userName=${action.data}`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      action.callBackFunction(response.data);
    }
    yield put(fetchCheckUserExistCompleted());
  } catch (error) {
    yield put(fetchCheckUserExistCompleted("Failed to Fetch Data"));
  }
}

function* initListSecurityQuestionsSaga(action) {
  yield put(fetchingListSecurityQuestions());
  const url = `/Login/ListSecurityQuestion`;
  try {
    const response = yield call(axios, url, getRequestDetails);
    if (response && response.status === 200) {
      yield put(fetchedListSecurityQuestions(response.data));
    }
    yield put(fetchListSecurityQuestionsCompleted());
  } catch (error) {
    yield put(fetchListSecurityQuestionsCompleted("Failed to Fetch Data"));
  }
}

function* initVerifySecurityQuestionAnswerSaga(action) {
  yield put(verifyingSecurityQuestionAnswer());
  const url = `/Login/ValidateSecurityAnswer`;
  try {
    postRequestDetails.data = action.data;
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 202) {
      yield put(verifySecurityQuestionAnswerSuccess());
      yield put(submittingQnASuccess());
      action.callBackFunction("success");
    } else {
      yield put(verifySecurityQuestionAnswerFailed());
      yield put(submittingQnAFailed());
      action.callBackFunction("error");
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
    yield put(verifySecurityQuestionAnswerFailed());
    action.callBackFunction("error");
  }
}
function* initQnAOTPSaga(action) {
  try {
    let url = `/Login/ValidateSecurityAnswerSendMFAOTP`;
    if (action.payload.token) url += `?token=${action.payload.token}`;
    const requestDetails = {
      ...postRequestDetails,
    };
    requestDetails.data = yield { ...action.payload, token: undefined };
    const response = yield call(axios, url, requestDetails);
    if (response.status === 200 || response.status === 202) {
      yield put(securityOTPSuccess());
    }
    if (
      response.status === 400 ||
      response.status === 404 ||
      response.status === 500 ||
      response.status === 401
    ) {
      const notification = buildNotification({
        message: response.data.messageCode,
        type: "danger",
      });

      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    // yield put(securityOTPFailed());
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
    yield put(verifySecurityQuestionAnswerFailed());
    action.callBackFunction("error");
  }
}

function* initConfirmGDPRMsgSaga(action) {
  yield put(confirmingGDPRMsg());
  const url = "/Users/ConfirmGDPRSettings";
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status === 202) {
      yield put(gdprMsgConfirmed());
      const notification = buildNotification({
        message: "notification.gdprMsg.confirmed",
        type: "success",
      });

      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    yield put(gdprMsgConfirmFailed());
  }
}

function* initIdleTimerSaga(action) {
  yield delay(10000);
  yield put(initIdleTimerCountdown(userLogoutTimeInSecs));
}

function* logoutSaga(action) {
  const url = "/Login/UpdateLastLogOut";
  const sessionDetails = yield sessionStorage.getItem("sessionDetails");
  try {
    let response = yield { status: false };
    if (sessionDetails) {
      const sessionObj = JSON.parse(sessionDetails);
      yield (putRequestDetails.data = {
        userId: sessionObj.userId,
        token: sessionObj.token,
      });
      response = yield call(axios, url, putRequestDetails);
    }

    if (response.status === 200) {
      let isClientSSO = yield JSON.parse(sessionStorage.getItem("isClientSSO"));
      let isClientLoggedIn = yield JSON.parse(
        sessionStorage.getItem("isClientLoggedIn")
      );
      yield put(loggedout());
      yield sessionStorage.removeItem("sessionDetails");
      yield sessionStorage.removeItem("languageId");
      yield sessionStorage.removeItem("i18nextLng");
      if (isClientSSO && isClientLoggedIn) {
        yield sessionStorage.removeItem("isClientSSO");
        yield sessionStorage.removeItem("isClientLoggedIn");
      }
      if (action.callback) {
        yield action.callback();
      } else {
        const clientDetails =
          JSON.parse(sessionStorage.getItem("clientDetails")) || {};
        const clientName = clientDetails ? clientDetails.name : "";
        window.location.href = "/?" + clientName;
      }
    }
  } catch (error) {
    const notification = buildNotification({
      message: "notification.errorOccurred",
      type: "danger",
    });

    store.addNotification({
      ...notification,
    });
  }
}

function* initRefreshTokenSaga(action) {
  yield delay(action.expirationTimeInSec * 1000);
  yield put(refreshToken());
}

function* getRefreshTokenSaga(action) {
  yield put(fetchingRefreshToken());
  const sessionDetails = JSON.parse(sessionStorage.getItem("sessionDetails"));
  const clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
  const oldRefreshToken = sessionDetails.token;
  const url = `/Login/refreshToken?token=${oldRefreshToken}`;
  try {
    const response = yield call(axios, url, postRequestDetails);
    if (response && response.status) {
      let expirationDate = new Date(
        new Date().getTime() + tokenExpirationTimeInSecs * 1000
      );
      const sessionStartsAtInSec = userLogoutTimeInSecs * 60;
      let sessionStartsAt = new Date(
        new Date().getTime() + sessionStartsAtInSec * 1000
      );
      const token = response.data;
      const updatedSessionDetails = {
        ...sessionDetails,
        token,
        expirationDate,
        sessionStartsAt,
      };
      sessionStorage.setItem(
        "sessionDetails",
        JSON.stringify(updatedSessionDetails)
      );

      yield put(checkAuthTimeout(sessionStartsAtInSec));
      yield put(initRefreshToken(tokenExpirationTimeInSecs - 60));
      axios.defaults.headers.Authorization = `Bearer ${token}`;
      axios.defaults.headers["X-App-Info"] = clientDetails.token;
    } else {
      yield put(refreshTokenFetchFailed());
    }
  } catch (error) {
    yield put(refreshTokenFetchFailed());
  }
}
